import React, { Fragment, useState, useEffect } from "react";
import {
  Stack,
  DetailsList,
  IColumn,
  PrimaryButton,
  IIconProps,
  IPivotItemProps,
  Pivot,
  PivotItem,
  SelectionMode,
  DefaultButton,
} from "@fluentui/react";
import Logo from "./Logo";
import { useStoreState, State, useStoreActions, Actions } from "easy-peasy";
import { RootState } from "../store/storeModel/RootState";
import { AppointmentReceived } from "../types/HL7/AppointmentReceived";
import moment from "../services/moment";
import { Navigate } from "react-router";
import SignOut from "./SignOut";
import { OnlyFor } from "../types/OnlyFor";
import { SorterInputWithColumns } from "../types/SorterInputWithColumns";
import CancelConfirmDialog from "./CancelConfirmDialog";
import { AppointmentCancelStatus } from "../types/Cancelling/AppointmentCancelStatus";

//i18n
import { useTranslation } from "react-i18next";
import { MyAppointments_i18n_Keys } from "../i18n/models/MyAppointments.i18n";
import { Common_i18n_keys } from "../i18n/models/Common.i18n";

export interface MyAppointmentsProps {}

const MyAppointments: React.FC<MyAppointmentsProps> = () => {
  const historyPivotKey = "previous";
  const userFullName = useStoreState(
    (state: State<RootState>) => state.root.userFullName
  );
  const getMyAppointments = useStoreActions(
    (actions: Actions<RootState>) => actions.root.getMyAppointments
  );
  const myAppointments = useStoreState(
    (state: State<RootState>) => state.root.myAppointments
  );
  const [timeFilter, setTimeFilter] = useState({ when: OnlyFor.Future });
  const sortMyAppointments = useStoreActions(
    (actions: Actions<RootState>) => actions.root.sortMyAppointments
  );
  const changeAppointmentCancelStatus = useStoreActions(
    (actions: Actions<RootState>) => actions.root.changeAppointmentCancelStatus
  );
  const { t } = useTranslation();

  const [navigate, setNavigate] = useState({ go: false, to: "" });

  const [lastItemPivot, setLastItemPivot] = useState<
    { props: IPivotItemProps } | undefined
  >(undefined);

  useEffect(() => {
    getMyAppointments(timeFilter.when);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter]);

  useEffect(() => {
    if (lastItemPivot?.props.itemKey === historyPivotKey) {
      data.columns = initialColumns;
    } else {
      data.columns = proxColumns;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter]);

  const sortByColumn = (
    ev: React.MouseEvent<HTMLElement>,
    column: IColumn
  ): SorterInputWithColumns => {
    const newColumns: IColumn[] = data.columns.slice();
    const currColumn: IColumn = newColumns.filter(
      (currCol) => column.key === currCol.key
    )[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    return {
      columns: newColumns,
      sorter: {
        columnKey: currColumn.fieldName as string,
        isSortedDescending: currColumn.isSortedDescending as boolean,
      },
    };
  };

  let initialColumns: IColumn[] = [
    {
      key: "speciality",
      name: t(Common_i18n_keys.speciality),
      fieldName: "speciality",
      minWidth: 150,
      maxWidth: 200,
      onRender: (item: AppointmentReceived) => {
        return <span>{item.specialty[0].coding[0].display}</span>;
      },
      onColumnClick: (ev, col) => {
        const sorted = sortByColumn(ev, col);
        setData({ columns: sorted.columns });
        sortMyAppointments(sorted.sorter);
      },
    },
    {
      key: "practitioner",
      name: t(Common_i18n_keys.practitioner),
      fieldName: "practitionerName",
      minWidth: 200,
      maxWidth: 200,
      onRender: (item: AppointmentReceived) => {
        return <span>{item.participant[1].actor.display}</span>;
      },
      onColumnClick: (ev, col) => {
        const sorted = sortByColumn(ev, col);
        setData({ columns: sorted.columns });
        sortMyAppointments(sorted.sorter);
      },
    },
    {
      key: "location",
      name: t(Common_i18n_keys.location),
      fieldName: "locationName",
      minWidth: 100,
      maxWidth: 100,
      onRender: (item: AppointmentReceived) => {
        return <span>{item.participant[2].actor.display}</span>;
      },
      onColumnClick: (ev, col) => {
        const sorted = sortByColumn(ev, col);
        setData({ columns: sorted.columns });
        sortMyAppointments(sorted.sorter);
      },
    },
    {
      key: "fecha",
      name: t(Common_i18n_keys.date),
      fieldName: "date",
      minWidth: 250,
      maxWidth: 250,
      onRender: (item: AppointmentReceived) => {
        return <span>{moment(item.start).format("LLLL")}</span>;
      },
      onColumnClick: (ev, col) => {
        const sorted = sortByColumn(ev, col);
        setData({ columns: sorted.columns });
        sortMyAppointments(sorted.sorter);
      },
    },
  ];

  const currentDate = new Date();

  let actionsColumn: IColumn = {
    key: "actions",
    name: "",
    fieldName: "",
    minWidth: 50,
    maxWidth: 50,
    onRender: (item: AppointmentReceived) => {
      const canCancelAppointment = item.status === "booked" && item.start && item.start > currentDate;
      return (
        <Fragment>
          {canCancelAppointment ? (
            <Fragment>
              <PrimaryButton
                text="Cancelar"
                onClick={() => {
                  changeAppointmentCancelStatus({
                    id: item.id,
                    newCancellingStatus: AppointmentCancelStatus.ON_CONFIRM,
                  });
                }}
              ></PrimaryButton>
              <CancelConfirmDialog appointment={item}></CancelConfirmDialog>
            </Fragment>
          ) : (
            <DefaultButton text="Cancelado" disabled={true}></DefaultButton>
          )}
        </Fragment>
      );
    },
  };

  let proxColumns: IColumn[] = [...initialColumns, actionsColumn];

  const [data, setData] = useState({
    columns: timeFilter.when === OnlyFor.Future ? proxColumns : initialColumns,
  });

  const goSearch = () => {
    setNavigate({ go: true, to: "/search" });
  };

  const addIcon: IIconProps = { iconName: "Add" };

  if (navigate.go) return <Navigate to={navigate.to} />;

  return (
    <Fragment>
      <div className="ms-Grid-col ms-sm10 ms-smOffset1 ms-md8 ms-lg10 ms-xxxl8 ms-mdOffset2 ms-mdOffset3 ms-lgOffset1 ms-xxxlOffset2 ms-depth-8 panelflotante info-back">
        <Stack tokens={{ childrenGap: 5 }} style={{ padding: "10px" }}>
          <Logo />
          <div>
            <div>
              <SignOut
                styles={{ float: "right", marginLeft: "10px" }}
              ></SignOut>
              <PrimaryButton
                style={{ float: "right" }}
                iconProps={addIcon}
                text={t(MyAppointments_i18n_Keys.my_newAppointmentButton)}
                onClick={goSearch}
              ></PrimaryButton>
            </div>

            <h4>
              {t(MyAppointments_i18n_Keys.my_header, { name: userFullName })}
            </h4>
          </div>

          <div
            style={{
              height: "450px",
              overflowY: "scroll",
              marginBottom: "10px",
            }}
          >
            <Pivot
              onLinkClick={(pivot, ev) => {
                setLastItemPivot(pivot);
                if (pivot && pivot.props.itemKey === "next") {
                  setTimeFilter({ when: OnlyFor.Future });
                  setData({ columns: proxColumns });
                }
                if (pivot && pivot.props.itemKey === "previous") {
                  setTimeFilter({ when: OnlyFor.Past });
                  setData({ columns: initialColumns });
                }
              }}
            >
              <PivotItem
                headerText={t(MyAppointments_i18n_Keys.my_nextAppointments)}
                itemKey="next"
              >
                <DetailsList
                  key="prox"
                  items={myAppointments}
                  columns={data.columns}
                  setKey="set"
                  isHeaderVisible={true}
                  selectionMode={SelectionMode.none}
                />
              </PivotItem>
              <PivotItem
                headerText={t(MyAppointments_i18n_Keys.my_history)}
                itemKey="previous"
              >
                <DetailsList
                  key="history"
                  items={myAppointments}
                  columns={data.columns}
                  setKey="set"
                  isHeaderVisible={true}
                  selectionMode={SelectionMode.none}
                />
              </PivotItem>
            </Pivot>
          </div>
        </Stack>
        <div style={{ textAlign: "center", padding: "10px" }}>
          <img style={{ width: "120px" }} src="/logo.png" alt="" />
        </div>
      </div>
    </Fragment>
  );
};

export default MyAppointments;
